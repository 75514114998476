body {
  font-family: 'Roboto', sans-serif;
  background-color: #fefefe;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
}

div:focus {
  outline: none;
}

/* Rich text editor container. */
.ephox-polish-editor-container {
    border: none;
    overflow: hidden;
}
.ephox-polish-layer-below-dialog, .ephox-chameleon-popup-container {
    -webkit-transform: translateZ(0);
}
/* Rich text editor buttons and toolbar. */
.ephox-polish-editor-container .ephox-chameleon-toolstrip, .ephox-polish-editor-container .ephox-chameleon-toolbar {
    background-color: #f6f6f6;
    border-bottom: none;
}
.ephox-polish-editor-container .ephox-chameleon-toolstrip .ephox-chameleon-toolbar-group, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group {
    border-color: #e4e4e4;
}
.ephox-polish-editor-container .ephox-polish-inline-popup-controls {
    background-color: #f6f6f6;
    color: #171717;
}
.ephox-polish-editor-container .ephox-polish-slick-widget .ephox-pastry-independent-button, .ephox-polish-editor-container .ephox-polish-toolbar-dialog .ephox-pastry-button.ephox-polish-stepper-button, .ephox-polish-editor-container .ephox-polish-inline-main-button-toggle {
    background-color: #f6f6f6;
    color: #171717;
    border-color: #e4e4e4;
}
.ephox-polish-editor-container .ephox-polish-slick-widget .ephox-pastry-independent-button:hover, .ephox-polish-editor-container .ephox-polish-toolbar-dialog .ephox-pastry-button.ephox-polish-stepper-button:hover, .ephox-polish-editor-container .ephox-polish-inline-main-button-toggle:hover, .ephox-polish-editor-container .ephox-polish-slick-widget .ephox-pastry-independent-button:active, .ephox-polish-editor-container .ephox-polish-toolbar-dialog .ephox-pastry-button.ephox-polish-stepper-button:active, .ephox-polish-editor-container .ephox-polish-inline-main-button-toggle:active {
    color: #171717;
    background: #ececec;
}
.ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-inline-widget .ephox-pastry-independent-button, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-chameleon-menu-widget .ephox-pastry-independent-button, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-palette-group > .ephox-pastry-independent-button, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-independent-button, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-palette-group > .ephox-pastry-statebutton, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-statebutton, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-dropbutton {
    background-color: #f6f6f6;
    color: #171717;
    border-color: #f6f6f6;
}
.ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-inline-widget .ephox-pastry-independent-button:hover, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-chameleon-menu-widget .ephox-pastry-independent-button:hover, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-palette-group > .ephox-pastry-independent-button:hover, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-independent-button:hover, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-palette-group > .ephox-pastry-statebutton:hover, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-statebutton:hover, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-dropbutton:hover, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-inline-widget .ephox-pastry-independent-button:active, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-chameleon-menu-widget .ephox-pastry-independent-button:active, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-palette-group > .ephox-pastry-independent-button:active, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-independent-button:active, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-palette-group > .ephox-pastry-statebutton:active, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-statebutton:active, .ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-dropbutton:active {
    color: #171717;
    background: #ececec;
}
.ephox-polish-editor-container .ephox-chameleon-toolbar .ephox-chameleon-toolbar-group > .ephox-pastry-statebutton.ephox-pastry-statebutton-selected {
    color: #171717;
    background: #ececec;
}
.ephox-polish-editor-container .ephox-polish-expandable-inline-popup .ephox-polish-inline-popup-expand-button {
    background: #3eb1c8;
    border-color: #3eb1c8;
}
.ephox-polish-editor-container .ephox-polish-expandable-inline-popup input:focus, .ephox-polish-editor-container .ephox-polish-editor-container .ephox-polish-expandable-inline-popup select:focus, .ephox-polish-editor-container .ephox-polish-editor-container .ephox-polish-expandable-inline-popup option {
    border-color: #3eb1c8;
}


.ephox-polish-layer-above-editor {
  z-index: 1500 !important;
}

.mention {
    display: inline-block;
    background: #efefef;
    border: 1px solid #3eb1c8;
    border-radius: 10px;
    padding: 2px 4px;
}
